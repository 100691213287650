<template>
  <div class="x" data-test="x" :dir="documentDirection">
    <SnippetConfigExtraParams :excludedExtraParams="excludedExtraParams" />
    <SnippetCallbacks />
    <Tagging :consent="true" />
    <UrlHandler />
    <MainModal v-if="isOpen" />
  </div>
</template>

<script lang="ts">
  import { SnippetCallbacks, SnippetConfig, useXBus, XEvent } from '@empathyco/x-components';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import {
    computed,
    ComputedRef,
    defineAsyncComponent,
    defineComponent,
    getCurrentInstance,
    inject,
    provide,
    ref,
    watch
  } from 'vue';
  import { useDevice } from './composables/use-device.composable';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';

  export default defineComponent({
    components: {
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler,
      MainModal: defineAsyncComponent(() =>
        import('./components/custom-main-modal.vue').then(m => m.default)
      )
    },
    setup() {
      const xBus = useXBus();
      const appInstance = getCurrentInstance();
      const { deviceName } = useDevice();
      const snippetConfig = inject<SnippetConfig>('snippetConfig')!;
      const isOpen = ref(false);

      const excludedExtraParams = [
        'callbacks',
        'uiLang',
        'consent',
        'documentDirection',
        'currency',
        'filters',
        'isolate',
        'queriesPreview',
        'cart'
      ];

      const openXEvents = ['UserOpenXProgrammatically', 'UserClickedOpenX'];

      const open = (): void => {
        isOpen.value = true;
      };

      openXEvents.forEach(event => xBus.on(event as XEvent, false).subscribe(open));

      const documentDirection = computed(() => {
        return (
          document.documentElement.dir ||
          document.body.dir ||
          (snippetConfig.documentDirection ?? 'ltr')
        );
      });

      const currencyFormat = computed(() => currencies[snippetConfig.currency!]);
      provide<string>('currencyFormat', currencyFormat.value);

      const queriesPreviewInfo = computed(() => snippetConfig.queriesPreview ?? []);
      provide<ComputedRef<QueryPreviewInfo[]> | undefined>(
        'queriesPreviewInfo',
        queriesPreviewInfo
      );

      watch(
        () => snippetConfig.uiLang,
        uiLang => appInstance?.appContext.config.globalProperties.$setLocale(uiLang ?? 'en')
      );

      watch(
        () => snippetConfig.cart as number,
        cart => {
          xBus.emit('CartNumberUpdated', cart);
        },
        { immediate: true }
      );

      watch(deviceName, device =>
        appInstance?.appContext.config.globalProperties.$setLocaleDevice(device)
      );

      return {
        isOpen,
        documentDirection,
        excludedExtraParams
      };
    }
  });
</script>

<style scoped>
  .x-modal :deep(.x-modal__content) {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
  }
  .x-main-modal {
    z-index: 100 !important;
  }
</style>
<style lang="scss">
  *:not(.x-keyboard-navigation *) {
    outline: none;
  }

  .x-banner,
  .x-promoted {
    &__title {
      display: none;
    }
  }
</style>
